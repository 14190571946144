@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';

.product-search-container {
  padding: 0rem $s-md $s-md 0rem !important;
  border-bottom: 0.1rem solid $lightestGrey;

  &.alterantive {
    border-bottom: 0rem;
  }

  .product-search {
    @include flex(row);
    width: 100%;
  }

  .thumb-container {
    flex-shrink: 0;
    box-sizing: border-box;

    &.sm {
      width: 5.6rem;
      height: 5.6rem;
    }

    &.lg {
      width: 6.4rem;
      height: 6.4rem;
    }

    .thumb {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .details {
    @include flex(column, $gap: $s-sm);
    flex-grow: 1;
    width: 100%;

    .title-area {
      @include flex(row, $gap: $s-md);

      .title {
        color: $primaryGrey;
      }
    }

    .attributes {
      @include flex(row, $gap: $s-sm);
      flex-wrap: wrap;
      gap: $s-sm;

      > span {
        text-wrap: nowrap;
      }
    }
  }

  .price-block {
    @include flex(column, $gap: $s-sm);
    flex-shrink: 0;

    .your-price {
      color: $secondaryGrey;
      @include label(sm);
    }

    .stock-check {
      > * {
        font-size: $f-xsm;
      }

      .loading {
        @include flex(row, $gap: $s-sm);
        @include flex-align(start, center);
        font-size: $f-xsm;

        > * {
          font-size: $f-xsm;
        }
      }
    }
  }

  .price-line {
    @include flex(row, $gap: $s-sm);
    @include flex-align(start, center);

    .price {
      flex-shrink: 0;
    }

    .unit-price {
      font-size: $f-lg;
      line-height: 1.8rem;
      font-weight: bold;
    }

    .discount {
      color: $primaryRed;
      font-size: $f-xsm;
      text-decoration: line-through;
    }

    .container {
      @include label(sm);
      color: $secondaryGrey;
    }
  }

  .error-pill {
    background-color: $lightError !important;
    color: $primaryGrey;
    height: fit-content;
  }

  @include gte-desktop {
    .info {
      @include flex(row, $gap: $s-md);
      flex-grow: 1;
      padding-right: $s-md;
      width: 70%;

      .title-area {
        @include flex-align(space-between, start);
      }
    }

    .thumb-container.sm {
      display: none;
    }

    .thumb-container.lg {
      display: block;
    }

    .block {
      box-sizing: border-box;
      padding-left: $s-md;
      width: 30%;
    }

    .error-pill-container {
      width: 30%;
    }
  }

  @include lte-tablet {
    .product-search {
      @include flex(column, $gap: $s-sm);
    }

    .thumb-container.lg {
      display: none;
    }

    .thumb-container.sm {
      display: block;
    }

    .details {
      .title-area {
        @include flex-gap-row($s-sm);

        .title {
          flex-grow: 1;
        }
      }
    }

    .price-block {
      margin-top: $s-sm;
    }
  }

  @include mobile {
    padding: $s-sm !important;

    .modal-search-header {
      position: relative;
      padding: 0 $s-sm;

      .price-line {
        @include flex(column);
        margin-bottom: $s-sm;

        .price {
          @include flex(row, $gap: $s-sm);
          @include flex-align(start, center);

          .unit-price {
            font-size: $f-xl;
            line-height: 2.8rem;
          }

          .discount {
            font-size: $f-sm;
          }
        }
      }
    }
  }
}
