@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';

.inner-content {
  .section-title {
    @include flex(row, $gap: $s-sm);
    @include flex-align(start, center);
    width: fit-content;

    .chev {
      padding: 0 1.3rem;
      cursor: pointer;
    }
  }

  .card {
    @include flex(row, $gap: $s-md);
    @include flex-align(space-between, flex-start);
    cursor: pointer;
    min-height: 5.6rem;
    box-sizing: border-box;

    .chev-right {
      flex: 0 0 2.4rem;
    }

    a {
      color: $primaryGrey;
    }

    &.link {
      padding: 0;
      a {
        padding: 0 $s-md;
        width: 100%;
        @include flex($gap: $s-sm);
        @include flex-align(start, center);
        span {
          padding: $s-sm 0;
        }
        svg {
          color: $mono-800;
        }
      }
    }
  }

  .section-nav {
    @include flex(row);
    @include flex-align(space-between, center);
    padding: $s-sm;

    svg {
      fill: $mono-600;
      flex-shrink: 0;
    }

    > * {
      color: $mono-800;
    }

    &.section-nav-link {
      padding: 0;

      a {
        width: 100%;
        pointer-events: all !important;
        @include flex();
        @include flex-align(start, center);

        span {
          padding: $s-sm;
        }

        &:hover,
        &:focus:active {
          text-decoration: none;
          color: $mono-800;
        }

        &:focus:active {
          font-weight: 700;
        }
      }
    }
  }

  @include mobile {
    @include flex(column, $gap: $s-md);
    @include flex-align(space-between, stretch);

    .section {
      @include flex(column);

      &:first-child {
        padding: $s-sm 0;

        > :first-child {
          margin-bottom: $s-sm;
        }
      }

      .section-title .parent-category > * {
        &.level-1-title {
          text-transform: uppercase;
          color: $mono-400;
          font-size: $f-xsm !important;
          line-height: 2.4rem;
        }
      }

      .section-description-container {
        @include flex(column, $gap: $s-sm);
        padding: $s-sm;

        .section-description {
          // multi-line overflow ellipses
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;

          &.expand-description {
            overflow: visible;
            display: block;
            height: auto;
          }
        }

        .read-more {
          width: fit-content;
          font-size: $f-sm;
        }
      }

      [class='site-settings'] {
        @include flex(column, $gap: $s-xs);

        [class='selected-setting'] {
          color: $mono-400 !important;
          font-size: $f-xsm !important;
        }
      }

      .cards {
        @include flex(column, $gap: $s-sm);

        .card {
          &.language,
          &.currency {
            [class='site-settings'] {
              @include flex(column, $gap: $s-xs);

              [class='setting'] {
                font-size: $f-md !important;
              }

              [class='selected-setting'] {
                color: $secondaryGrey;
                $font-size: $f-sm;
              }
            }
          }

          .chev-right {
            align-self: center;
          }
        }

        .level-3 {
          a {
            pointer-events: none;
          }
        }
      }
    }
  }

  @include gte-tablet {
    .section-title {
      color: $secondaryGrey;
    }
  }

  @include lte-tablet {
    .section {
      .parent-category {
        @include flex(column, $gap: $s-xs);
        padding-left: $s-sm;
      }

      .card {
        font-size: $f-md;
        line-height: 1.9rem;
        &:not(.link) {
          padding: $s-md;
        }
        span {
          font-size: $f-md;
        }
      }
    }
  }

  @include tablet {
    .section {
      @include flex(column, $gap: $s-sm);

      &.site-settings-section {
        display: none;
      }

      .section-title .parent-category {
        margin-bottom: $s-sm;
        > * {
          font-size: $f-lg;
        }
      }

      .cards {
        @include flex(column, $gap: $s-sm);

        .card {
          @include flex-align(space-between, center);
          width: 100%;
          padding-right: $s-md;
        }

        > div {
          width: calc(50% - 1.6rem);
          margin-bottom: $s-md;
          box-sizing: border-box;
        }
      }
    }
  }

  @include gte-desktop {
    .section {
      @include flex(column, $gap: $s-lg);

      .section-title span {
        font-size: $f-md;
      }

      .cards {
        @include flex(column);

        .card {
          font-size: $f-lg;
          line-height: 2.2rem;

          > * {
            font-size: inherit;
          }

          &:not(.link) {
            padding: $s-md $s-lg $s-md $s-md;
          }

          &.active,
          &:hover {
            background-color: $blue-50;
          }
        }

        .section-nav {
          cursor: pointer;

          a {
            pointer-events: none;
          }

          &:hover,
          &.selected-section-nav {
            background-color: $blue-50;
          }

          &.selected-section-nav {
            font-weight: 700;
          }
        }
      }
    }

    &.menu-level-1 {
      .chev {
        display: none;
      }
    }
  }
}
