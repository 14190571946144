@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';

.featured-brands {
  .section-title .parent-category {
    font-size: $f-md;
  }

  .section-title {
    .chev {
      padding: 0 1.3rem;
      cursor: pointer;
    }
  }

  .brands {
    .img-container img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .view-all {
      text-align: center;
    }
  }

  @include mobile {
    .section-title {
      @include flex(row, $gap: $s-sm);
      @include flex-align(start, center);
      padding: 0.2rem $s-lg $s-sm $s-md;

      .chev {
        padding: 1rem 1.3rem;
        fill: $mono-800;
      }

      .parent-category {
        font-size: $f-sm;
      }
    }

    .brands {
      @include flex(column, $gap: $s-md);
      padding: $s-md;

      .heading {
        color: $black;
        font-size: $f-md !important;
      }

      .thumbnails {
        display: grid;
        grid-template-columns: 9.3rem 9.3rem 9.3rem;
        grid-template-rows: auto;
        width: 100%;
        gap: $s-lg calc((100% - (9.3rem * 3)) / 2);

        .image-link {
          @include flex(row);
          @include flex-align(center);

          .img-container {
            width: 9.3rem;
            height: 7rem;
          }
        }
      }

      .view-all {
        padding: $s-lg $s-sm $s-md $s-sm;
      }
    }
  }

  @include tablet {
    @include flex(column);
    @include flex-align(start, stretch);
    flex: 1;

    .section-title {
      color: $secondaryGrey;
      @include flex(row, $gap: $s-sm);
      @include flex-align(start, start);
    }

    .brands {
      height: 100%;
      flex: 1;

      .heading {
        margin: $s-md 0 $s-sm 0;
        font-size: $f-lg;
        line-height: 2.2rem;
      }

      .thumbnails {
        display: grid;
        grid-gap: $s-md $s-lg;
        justify-content: center;
      }
    }
  }

  @include gte-tablet {
    .brands {
      @include flex(column, $gap: $s-sm);
      @include flex-align(center, stretch);
      height: 100%;

      .content {
        flex: 1;
        @include flex(column);
        @include flex-align(center, stretch);
        padding: 0 $s-xxl;

        .thumbnails {
          display: grid;
          display: -ms-grid;
          grid-template-rows: auto;
          -ms-grid-rows: auto;
          grid-template-columns: repeat(auto-fit, 8.8rem);
          -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
          justify-content: center;

          .img-container {
            width: 8.8rem;
            height: 6.62rem;
          }

          //--START: custom IE 11 styles -- //
          a {
            display: block;
          }

          > {
            -ms-grid-column: 1;
            -ms-grid-row: 1;
          }

          // rows
          > *:nth-child(1n + 6) {
            -ms-grid-row: 2;
          }
          > *:nth-child(1n + 11) {
            -ms-grid-row: 3;
          }
          > *:nth-child(1n + 16) {
            -ms-grid-row: 4;
          }

          // columns
          > :nth-child(2),
          > :nth-child(5n + 2) {
            -ms-grid-column: 2;
          }
          > :nth-child(3),
          > :nth-child(5n + 3) {
            -ms-grid-column: 3;
          }
          > :nth-child(4),
          > :nth-child(5n + 4) {
            -ms-grid-column: 4;
          }
          > :nth-child(5),
          > :nth-child(5n + 5) {
            -ms-grid-column: 5;
          }
          //-- END: custom IE 11 styles -- //
        }

        .view-all {
          padding: $s-lg $s-sm;
        }
      }
    }
  }

  @include gte-desktop {
    box-sizing: border-box;
    padding: $s-sm;
    border-bottom: 0.4rem solid $transparent;

    .section-title {
      display: none;
    }

    .brands {
      .heading {
        color: $secondaryGrey;
        font-size: $f-md;
        line-height: 1.9rem;
        margin: 0 0 $s-md 0;
      }

      .content {
        .thumbnails {
          grid-gap: $s-xxl $s-lg;
        }
      }
    }
  }
}

.featured-brands-container {
  width: 100%;
}
