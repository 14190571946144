@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';

.header-container {
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.16);
  background-color: $white;

  .top-links-container {
    display: none;
  }

  .nav-bar {
    @include flex-align(start, center);

    .nav-bar-icons {
      max-width: 100%;
      flex-grow: 1;

      .icon-button {
        @include flex-align(space-between, center);

        .menu-icon-wrapper {
          padding: $s-xs;

          .menu-icon-container {
            padding: 0 !important;
            width: 24px;
            height: 24px;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;

            span {
              display: block;
              position: absolute;
              height: 2px;
              width: 20px;
              background: #1f1f1f;
              border-radius: 0;
              opacity: 1;
              left: 2px;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transition: 0.25s ease-in-out;
              -moz-transition: 0.25s ease-in-out;
              -o-transition: 0.25s ease-in-out;
              transition: 0.25s ease-in-out;
            }

            span:nth-child(1) {
              top: 4px;
            }

            span:nth-child(2),
            span:nth-child(3) {
              top: 11px;
            }

            span:nth-child(4) {
              top: 18px;
            }

            &.open {
              span:nth-child(1),
              span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
              }

              span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
              }

              span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }
          }
        }

        .icon {
          padding: $s-xs; //to include product menu icon
          border-radius: 2.4rem;
          overflow: visible; //due to hidden piece of basket icon
        }

        .icon-container {
          position: relative;

          .icon {
            width: 2.4rem;
            height: 2.4rem;
          }
          .notification {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 30px;
            background: $primaryRed;
            display: block;
            position: absolute;
            top: 0.8rem;
            right: 0.3rem;
            z-index: 1;
            transition: 0.3s linear;

            &.basket-notification {
              top: 0.4rem;
              right: 0.4rem;
              border: 0.2rem solid $white;
            }

            span {
              display: none;
            }
          }
        }

        &:hover,
        &.animate {
          .icon {
            background-color: $blue-50 !important;
            transition: 0.3s linear;
          }

          .notification {
            width: 100%;
            height: 100%;
            top: 0 !important;
            right: 0 !important;
            color: $white;
            @include flex();
            @include flex-align(center, center);

            &.basket-notification {
              border: none;
            }

            span {
              display: block;
              font-size: $f-xsm;
            }
          }
        }

        .down {
          // display: none;
        }
      }

      .basket .vat {
        color: $secondaryGrey;
        white-space: nowrap;
        @include label(sm);
        line-height: $f-md;
      }
    }
  }

  @include gte-tablet {
    &.header-container-minimal,
    &.header-container-basic {
      .nav-bar {
        .nav-bar-icons {
          justify-content: flex-end;
        }
      }
    }
    &.header-container-minimal {
      padding: $s-sm;
    }
  }

  // mobile
  @include mobile {
    box-shadow: 0 -0.4rem 0.4rem rgba(0, 0, 0, 0.16);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999 !important;
    border-top: 0.4rem solid $primaryRed;

    .nav-bar {
      .logo {
        display: none;
      }

      .nav-bar-icons {
        @include flex-align(space-between, center);

        .logo-mobile {
          display: block;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .pointer {
          display: none;
        }

        .search-container {
          display: none;
        }

        .icon-button:not(:last-of-type),
        .search-container {
          margin-right: $s-sm;
        }

        .info {
          display: none;
        }

        .parts-list {
          order: 2;
        }

        .account {
          order: 1;
        }

        .basket {
          order: 2;

          .info {
            display: none;
          }
        }
      }
    }
  }

  @include tablet {
    .pointer.logged-in {
      bottom: -2.5rem;
    }
  }

  // tablet & above
  @include gte-tablet {
    border-bottom: 0.4rem solid $primaryRed;

    &.menu-open {
      z-index: 996;
      position: relative;
    }

    .nav-bar {
      .logo {
        display: block;
        width: 4.4rem;
        height: 4.4rem;
        margin-right: $s-lg;
      }

      .nav-bar-icons {
        @include flex-align(space-between, flex-end);

        .logo-mobile {
          display: none;
        }

        .icon-button {
          cursor: pointer;
          &:not(:last-of-type) {
            margin-right: $s-md;
            position: relative;
          }

          .info {
            @include flex(row, $gap: $s-xs);
            line-height: 2.4rem;

            .info > span {
              @include label();
            }
          }
        }

        .account {
          @include flex(column);
          @include flex-align(start, center);

          position: relative;
          margin-right: $s-md;

          .icon-button {
            margin-right: 0 !important;
          }
        }

        .menu {
          position: relative;
        }

        .search-container {
          flex-basis: 20rem;
          flex-grow: 1;
          padding-bottom: $s-xs;
          margin-right: $s-md;
        }

        .search-icon {
          display: none;
        }

        .basket {
          .basket-details {
            @include flex-align(start, center);
            .icon {
              align-self: flex-end;
            }
          }
          .vat {
            margin-left: $s-xs;
          }
        }
      }
    }

    .pointer {
      position: absolute;
      transform: rotate(180deg);
      color: $primaryRed;
      bottom: -3.3rem;
    }
  }

  // desktop and above
  @include gte-desktop {
    .header-card {
      padding: $s-sm $s-md;
      max-width: $inner-page-width;
      position: relative;
    }

    .top-links-container {
      @include flex(row);
      @include flex-align(space-between);

      .top-links {
        display: flex;

        .link,
        .vat {
          min-width: 0;
          padding: 0 $s-sm;
        }

        $link-border: 0.1rem solid $lightestGrey;

        &.info-links {
          .link {
            border-right: $link-border;
          }
        }

        &.site-settings-container {
          .link,
          .vat {
            border-left: $link-border;
          }

          .site-icon {
            align-self: center;
            padding-right: $s-sm;
          }

          .site-setting {
            position: relative;

            .site-setting-modal-container {
              position: absolute;
              top: 100% !important;
              left: 0 !important;
              z-index: 1001;
              width: 18.5rem;
              box-shadow: 0 0.4rem 1.4rem rgba(0, 0, 0, 0.25);
              border-radius: 0.8rem;
              transform: unset !important;

              padding: 0 !important;

              .site-setting-dropdown {
                > * {
                  padding: 1.2rem $s-md;
                  cursor: pointer;
                  line-height: $f-md;

                  &:hover {
                    background-color: $blue-50;
                  }

                  &:active {
                    background-color: $blue-100 !important;
                  }
                }

                :not(:last-child) {
                  border-bottom: $link-border;
                }
              }
            }
          }
        }

        .vat {
          @include flex(row, $gap: $s-xs);
          @include flex-align(start, center);

          .unselected {
            color: $secondaryGrey;
          }
        }
      }
    }

    .nav-bar {
      margin-top: $s-md;

      .logo {
        margin-right: $s-xl;
      }

      .nav-bar-icons {
        .icon-button:not(:last-of-type),
        .search-container {
          margin-right: $s-lg;
        }
      }
    }

    &.header-container-minimal,
    &.header-container-basic {
      .top-links-container {
        display: none;
      }

      .nav-bar {
        margin-top: 0;
      }
    }
  }
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  height: calc(100% - 128px);
  z-index: 995;

  @include lte-tablet {
    display: none;
  }
}
