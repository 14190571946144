@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';

.desktop-categories {
  @include flex(row, $gap: $s-sm);
  width: 100%;

  .level-2-3 {
    width: calc(100% / 2);
    box-sizing: border-box;

    &.level-2 {
      border-right: 0.1rem solid #cccccc;
      padding-right: $s-sm;
    }

    .section-title {
      @include flex(column, $gap: $s-sm);
      padding: $s-sm;

      .title {
        font-size: $f-md;
        margin-bottom: 0;
      }

      .link {
        font-size: $f-sm;
        text-wrap: nowrap;
      }
    }

    .categories {
      @include flex(column);

      .category {
        @include flex(row);
        @include flex-align(space-between, center);
        padding: $s-sm;
        cursor: pointer;

        .chev-right {
          fill: $mono-800;
        }

        &:hover,
        &.selected-category {
          background-color: $blue-50;
        }

        &.selected-category {
          font-weight: 700;
        }

        &.level-3-category {
          color: $mono-800;

          &:active {
            text-decoration: none;
          }
        }
      }
    }
  }
}
