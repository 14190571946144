@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';

body,
html {
  width: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification-bar {
  background-color: $warning;
  padding: $s-sm;
  @include flex(row);

  p {
    flex-grow: 1;
    text-align: center;
  }
  .close {
    cursor: pointer;
  }
}

.page-content-wrapper {
  @include mobile {
    min-height: calc(80vh - 5rem);
  }
  @include tablet {
    min-height: calc(80vh - 5rem);
  }
  @include gte-desktop {
    min-height: calc(80vh - 12rem);
  }

  // required to avoid SSR flicker
  .content {
    display: none;
  }

  &.page-content-wrapper-with-banner {
    [class*='campaign-container'] {
      margin-top: $s-md;
    }
  }
}

footer {
  @include media-breakpoint-down(sm) {
    margin-bottom: 56px;
  }
}
