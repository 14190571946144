@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';

.account-menu-container {
  background-color: $white;
  padding: $s-md;
  @include flex(column, $gap: $s-md);
  box-sizing: border-box;
  z-index: 999;
  position: absolute;

  .cta-container {
    @include flex(column, $gap: $s-md);
  }

  .menu-container {
    @include flex(column);
    @include flex-align(start, stretch);

    a {
      @include flex(row, $gap: $s-sm);
      @include flex-align(start, center);
      padding: $s-sm;
      font-weight: bold;
    }

    .chev {
      display: none;
    }
  }

  @include mobile {
    bottom: 52px;
    top: 0;
    width: 100%;

    .cta-container {
      width: 200px;
    }
  }

  @include tablet {
    width: 100%;
    height: calc(100% - 76px);

    .cta-container {
      width: 300px;
    }

    .menu-container {
      span {
        width: 100%;
      }

      .chev {
        display: inline;
      }
    }
  }

  @include lte-tablet {
    &.desktop {
      display: none;
    }

    .menu-container {
      flex-grow: 1;
    }

    .cta-container {
      align-self: center;
    }
  }

  @include gte-desktop {
    border: 2px solid $mono-200;
    width: 23.3rem;
    top: 64px;
    box-shadow: 0px 4px 6px -1px #1111111a;

    &.lte-tablet {
      display: none;
    }
  }
}
