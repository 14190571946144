@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';

.search-container {
  .label {
    color: $secondaryGrey;
    margin-bottom: $s-md;

    &.suggested-products-text {
      padding-top: $s-sm;
    }
  }

  .recent-searches {
    @include flex(column, $gap: $s-md);

    > * {
      @include flex(row);
      @include flex-align(space-between);

      .recent-link {
        color: $primaryGrey;
      }

      .close-icon {
        color: $secondaryGrey;
        cursor: pointer;
      }
    }

    .clear-all-recent {
      width: fit-content;
      cursor: pointer;
    }
  }

  .suggested-searches {
    @include flex(column, $gap: $s-md);

    .suggested-link {
      @include flex-gap-col($s-sm);

      .suggested-search-info {
        cursor: pointer;
        @include flex(row, $gap: $s-xs);

        .suggested-search {
          color: $primaryGrey;
        }

        .extra-info {
          color: $secondaryGrey;
        }
      }

      .shop {
        color: $secondaryGrey;
        font-style: italic;
      }
    }

    .suggested-products-comparison-link {
      align-self: center;
    }
  }

  @include mobile {
    position: fixed;
    z-index: 998;

    .header {
      @include flex(row, $gap: $s-sm);
      @include flex-align(start, center);
      padding-left: $s-sm;

      .search-field {
        max-width: 100%;
        flex-grow: 1;
      }

      .close {
        color: $secondaryGrey;
        cursor: pointer;
      }
    }

    .info {
      padding: $s-md $s-sm;
      background-color: $lightestGrey;
    }

    .modal {
      .recent-searches,
      .suggested-searches {
        padding: $s-md;
      }
    }

    div[class*='fullscreen'] {
      --modal-height: calc(100vh - 5.9rem - 0.4rem) !important; //total mobile screen height - height of nav-bar - top shadow of nav-bar
    }
  }

  @include gte-tablet {
    .modal {
      position: absolute !important;
      top: 4.8rem !important;
      transform: unset !important;
      box-shadow: 0 4px 14px 0 #00000040 !important;
    }
  }

  @include tablet {
    .modal {
      left: -0.8rem !important;
      right: -0.8rem !important;
    }

    .suggested-searches {
      .suggested-products-comparison-link {
        margin-bottom: $s-md;
      }
    }
  }

  @include gte-desktop {
    .modal {
      left: 0 !important;
      right: 0 !important;
    }

    .suggested-searches {
      .suggested-products-comparison-link {
        margin-top: -$s-md;
      }
    }
  }
}
