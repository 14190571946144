@import "~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss";

.stock {
  display: block;
  width: fit-content;
  background-color: $lightWarning;
  color: $primaryGrey;
  font-size: $f-xsm;

  &.instock {
    background-color: $lightSuccess;
    color: $success;
  }
  &.discontinued {
    background-color: $lightError;
  }
  &.backordered {
    background-color: $lightWarning;
  }
}
