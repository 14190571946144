@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';

.product-menu-container {
  $header-offset-desktop: 11.9rem;
  $header-offset-tablet: 8.4rem;

  .menu-container {
    background-color: $white;

    .content {
      background-color: $white;
    }

    .vat {
      @include flex(row, $gap: $s-xs);
      @include flex-align(start, center);
      padding: 8px 0;
      margin-top: $s-sm;

      .unselected {
        color: $secondaryGrey;
      }
    }

    .settings-menu {
      @include flex(column);
      @include flex-align(start, stretch);
      padding: 0 $s-md $s-sm $s-md;

      .section-title {
        @include flex(row, $gap: $s-sm);
        @include flex-align(start, center);
        padding: $s-sm $s-sm $s-sm 0;
        line-height: 4.3rem;

        .back {
          fill: $mono-800;
          padding: 0 1.3rem;
        }
      }

      .radio {
        padding: $s-md 0 $s-xxl 0;
      }

      &.settings-menu-categories {
        padding: 0;
      }
    }
  }

  @include mobile {
    position: absolute;
    top: 0;
    bottom: 5.6rem;
    border: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: $lightestGrey;
    z-index: 998;

    .menu-container {
      .content {
        position: fixed;
        padding: $s-md;
        top: 0;
        left: 0;
        right: 0;
        height: calc(100% - 5.2rem);
        overflow-y: auto;
        z-index: 998;
        box-sizing: border-box;

        &.brands {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .overlay {
        display: none;
      }
    }
  }

  @include gte-tablet {
    position: relative;

    .menu-container {
      z-index: 999;
      position: absolute;
      width: 100%;

      svg {
        color: $secondaryGrey;
      }

      .content {
        position: relative;
        z-index: 999;
        box-sizing: border-box;
        @include flex(column);
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      z-index: 995;
      background: rgba($transparent, 0.4);
    }
  }

  @include tablet {
    .menu-container {
      .content {
        padding: $s-md;
        width: 100%;
        min-height: 324px;
        background-color: $mono-50;
      }
    }
  }

  @include gte-desktop {
    .menu-container {
      height: 33.6rem;
      padding: $s-sm;
      @include flex(row, $gap: $s-sm);
    }

    .content {
      width: calc(100% / 3);
      border-right: 0.1rem solid #cccccc;
      padding-right: $s-sm;
      flex-shrink: 0;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
