@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/index.css';
@import '~rs-emd-ui-atoms/dist/styles/design-tokens/styles.scss';
@import '../rs-emd-ui-modules/src/styles/styles.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
