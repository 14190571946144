@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';

.site-wide-banner {
  max-width: $inner-page-width;
  margin: $s-md auto 0 auto;
  padding: 0 $s-md;

  .site-wide-banner-content {
    @include flex(column, $gap: $s-xs);
    padding: $s-sm 0;

    .site-wide-banner-headline {
      font-weight: bold;
      font-size: $f-md;
      line-height: 2.4rem;
    }

    .site-wide-banner-body {
      line-height: 2rem;
      font-size: $f-sm;
    }

    p {
      margin: 0;
    }

    .btn {
      margin-top: $s-sm;
      width: fit-content;
    }
  }

  @include mobile {
    margin: 0;
    padding: 0;
  }
}
